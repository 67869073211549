body
    padding-top 75px
    font-family opensans-light

button
    cursor pointer
    
.bold
    font-weight bold
    
input, select, textarea
    background cinza-fundo
    border 1px solid cinza-destaque
    font-size 20px
    padding 7px 10px
    font-family opensans-light
    border-radius (5px)
    
// MENU
.topo
    width 100%
    min-height 75px
    background branco
    border-bottom 1px solid azul-logo
    font-size 0
    position fixed
    z-index 1
    top 6px
    
.logo
    width 15%
    padding 0.5%
    text-align center
    display inline-block
    vertical-align middle
    img
        max-width 90%
    
.menuTopo
    width 79%
    min-height 75px
    font-size 0
    display inline-block
    vertical-align middle
    // retirar
    text-align right
    li
        width 9%
        height 102px
        display inline-block
        a
            display block
            padding 0 1%
            font-size 0.8vw
            text-align center
            text-decoration none
            font-weight bold
            color preto
            margin-top 64px
            &:hover
                color azul-logo
            
.btnResponsivo
    position relative
    z-index 1
    font-size 30px
    display none
    float right
// MENU

.depoimento
    width 100%
    padding 170px 0
    margin-bottom 30px
    background url('../images/back-depoimento.jpg') center no-repeat azul-logo
    background-size cover
    p
        width 70%
        margin 20px auto 0 auto
        text-align right
        color branco
        font-size 18px
    p:first-of-type
        width 70%
        line-height 30px
        margin 0 auto
        padding-bottom 30px
        text-align center
        color branco
        font-size 24px
        border-bottom 1px solid branco

// RESTRITO
.barraRestrita
    width 100%
    height 6px
    top 0
    position fixed
    z-index 1
    background azul-logo
    
#restrito
    background azul-logo
    border 0
    top 0
    right 100px
    padding 10px
    color branco
    position fixed
    z-index 10
    border-radius (0 0 5px 5px)
    .fa
        font-size 18px
        margin-right 10px
    


// MEDIA QUERY

@media (max-width: 1460px)
// MENU
    .menuTopo
        li
            a
                font-size 1vw
            
            
@media (max-width: 1040px)
// MENU
    .menuTopo
        li
            width 13%
            a
                font-size 1.5vw
            
            
@media (max-width: 769px)
// MENU
    .menuTopo
        width 100%
        left 0
        position absolute
        overflow hidden
        z-index -1
        li
            width 100%
            display block
            border-bottom 1px solid cinza-destaque
            a
                padding-left 20px
                font-size 15px
                text-align left
                background cinza-fundo
                color azul-logo
                text-decoration none
                margin-top 0
                height 100%
                line-height 104px
                &:hover
                    background azul-logo
                    color branco

    .btnResponsivo
        display block
        border 1px solid azul-logo
        padding 8px 5px
        margin 22px 20px 0 0
        span
            background azul-logo
            display block
            width 30px
            height 5px
            margin 1px
    
    .verMenu
        visibility visible
        top 75px
        opacity(1)
        transition (all 0.3s linear)
        
    .escondeMenu
        visibility hidden
        top 75px
        opacity(0)
        transition (all 0.3s linear)
        
    .logo
        padding 15px
        img
            max-width none
            width 163px
            
    #linkEmiolo
        float none !important
        margin 30px 0 0 5% !important
// MENU



// RODAPÉ
#rodape
    background azul-logo
    padding 20px 0
    color branco
#infosRodape
    margin-left 5%
    display inline-block
    p
        margin 5px 0
        .fa
            margin-right 10px
            
#iconeFacebook
    color branco
    font-size 27px
    margin-top 20px
    display table
    
#linkEmiolo
    display inline-block
    height 35px
    background url('http://emiolo.com/logo2.png') 90% center no-repeat
    background-size auto 27px
    width 84px
    float right
    margin-right 5%
    
    
@media (max-width: 455px)
    #restrito
        top 81px
        right 30px
        z-index 1