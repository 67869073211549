@import "html-reset.styl"
@import "structure.styl"
@import "layout.styl"

.banner
    text-align center
    h1
        padding-top 190px
        font-weight lighter
    p
        color branco
        width 50%
        margin 0 auto 40px auto
        line-height 23px
        span
            border-top 1px solid cor-destaque
            display block
            width 40%
            margin 15px auto
    button 
        padding 8px 25px
        border 1px solid cor-destaque
        color cor-destaque
        background transparent
        border-radius (7px)

h2
    width 90%
    margin 0 auto
    font-size 30px
    display block
    padding-bottom 10px
    border-bottom 1px solid preto
    
.filtroImg
    height 520px
    background url("../images/filter.png") rgba(1,4,21, 0.8)

// SLUÇÕES
#solucoes
    padding 50px 0 20px 0
    display table
    background cinza-fundo
    h2
        color azul-logo
        border-color azul-logo
    .col2
        width 40%
        margin 0 5%
        float left
        
.logoSolucoes
    margin-bottom 20px
    text-align center
    padding 40px 0 20px 0
    border-bottom 1px solid cinza-destaque
    img
        max-width 100%
    
.linkMais
    margin-top 40px
    display inline-block
    text-decoration none
    color cor-destaque
    span
        background cor-destaque
        display inline-block
        vertical-align middle
        border-radius (100%)
        color branco
        margin-right 10px
        padding 4px 9px
        font-size 25px


// QUEM SOMOS
#quemSomos
    padding 50px 0
    display table
    width 100%
    h2
        margin-bottom 40px
        color cor-destaque
        border-color cor-destaque
    .textQuemSomos, .slideQuemSomos
        margin 0 5%
    
.textQuemSomos
    width 48%
    text-align justify
    float left
    p
        margin 15px 0
    
.slideQuemSomos
    width 30%
    float right
    position relative
    border 1px solid cor-destaque
    img
        width 100%
        
.flex-prev, .flex-next
    padding 8px 11px
    text-decoration none
    color cor-destaque 
    background branco
    position absolute
    bottom 0

.flex-next
    right 0
    
    
// BLOG
#blog
    padding 50px 0
    display table
    .linkMais
        margin-left 5%
    h2
        margin-bottom 30px
        color cor-destaque
        border-color cor-destaque
    .col2
        width 40%
        margin 15px 5%
        float left
    
.imgBlog
    display inline-block
    width 120px
    height 120px
    vertical-align middle
    border 1px solid cor-destaque
    
.textoPost
    display inline-block
    calc('width', s("100% - 150px"))
    vertical-align middle
    margin-left 20px
    p
        max-height 68px
        overflow hidden
    h4
        color cor-destaque
        width 100%
        white-space nowrap 
        overflow hidden
        text-overflow ellipsis 
    span
        display block
        border-bottom 1px solid cinza-destaque
        padding-bottom 7px
        margin-bottom 5px
        color azul-logo
        width 100%
        white-space nowrap 
        overflow hidden
        text-overflow ellipsis 
        
        
@media (max-width: 930px)
    .textQuemSomos
        float none
        width auto
        
    .slideQuemSomos
        float none
        width auto
        margin-top 20px !important
        
    
@media (max-width: 769px)
    .banner
        p
            width 90%
        h1
            padding-top 120px
            
    #blog
        .col2
            float none
            width auto
            
@media (max-width: 540px)
    #solucoes
        .col2
            float none
            width auto