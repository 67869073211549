body

ul, li
    margin 0
    padding 0
    list-style none

//MIXIN    
border-radius()
    -webkit-border-radius arguments
    -moz-border-radius arguments
    border-radius arguments
    
calc(prop, val)
    {prop} s("-webkit-calc(%s)",val)
    {prop} s("-moz-calc(%s)",val)
    {prop} s("calc(%s)", val)
    
rotate(degrees)
    -webkit-transform: s("rotate(%s)", degrees)
    -ms-transform: s("rotate(%s)", degrees)
    transform: s("rotate(%s)", degrees)

    
opacity()
    -webkit-opacity arguments
    -moz-opacity arguments
    opacity arguments
    
box-shadow()
   -webkit-box-shadow arguments
   -moz-box-shadow arguments
   box-shadow arguments

transition()
    transition         arguments
    -moz-box-shadow    arguments
    -webkit-transition arguments
    
// FONTS
@font-face
    font-family opensans-light
    font-style normal
    src url(../fonts/opensans-light.ttf)
    src:url('../fonts/opensans-light.eot?#iefix') format('embedded-opentype'),
    url('../fonts/opensans-light.woff') format('woff'),
    url('../fonts/opensans-light.ttf') format('truetype'),
    url('../fonts/opensans-light.svg#font-icon') format('svg');
    

// COLORS
azul-logo = #13216f
cor-destaque = #ed7811
cinza-fundo = #eaeaea
cinza-destaque = #ccc
preto = #000
branco = #fff